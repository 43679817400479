<template>
  <div
      class="slider">
    <slide-item
        v-if="!showAboutEmployee && slidesLoaded"
        :slide="computedSlide"
        :buttons="buttons[current_slide]"/>
    <about-employee
        v-else-if="showAboutEmployee"/>
  </div>
</template>

<script>
import SlideItem from "@/components/SlideItem";
import AboutEmployee from "@/components/AboutEmployee";

export default {
  name: 'Slider',
  mounted() {
    fetch('/admin/callCenterWorker/client/getSlides/')
        .then(response => response.json())
        .then(json => {
          this.slides = json;
          this.slidesLoaded = true;
        });
  },
  data() {
    return {
      showAboutEmployee: false,
      current_slide: 1,
      slides: [],
      slidesLoaded: false,
      buttons: {
        1: [
          {
            text: 'Начать',
            action: this.next
          },
        ],
        2: [
          {
            text: 'Далее',
            action: this.next
          },
        ],
        3: [
          {
            text: 'Далее',
            action: this.next
          },
        ],
        4: [
          {
            text: 'Далее',
            action: this.next
          },
        ],
        5: [
          {
            text: 'Да',
            action: () => this.showAboutEmployee = true
          },
          {
            text: 'Нет',
            action: () => this.setSlide(6)
          },
        ],
        6: [],
      }

    }
  },
  computed: {
    computedSlide() {
      return this.slides[this.current_slide];
    }
  },
  watch: {
    showAboutEmployee: function (val) {
      if (val) {
        this.current_slide = 7;
        this.saveSlideToDb();
      }
    }
  },
  methods: {
    getId() {
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri);
      return params.get("id");
    },
    prev() {
      if (this.current_slide !== 1)
        this.current_slide--
    },
    next() {
      if (this.current_slide !== Object.keys(this.slides).length) {
        this.current_slide++;
        this.saveSlideToDb();
      }
    },
    setSlide(slideNum) {
      this.current_slide = slideNum;
      this.saveSlideToDb();
    },
    saveSlideToDb() {
      fetch('/admin/callCenterWorker/client/saveSlide/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: 'id=' + this.getId() + '&current_slide=' + this.current_slide,

      }).then(response => response.text());
    }
  },
  components: {
    SlideItem,
    AboutEmployee
  },
}
</script>

<style>

</style>
