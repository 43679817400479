<template>
  <div>
    <div v-html="slide.html"></div>
    <a href="#" class="button" v-for="button in buttons" @click="button.action">{{ button.text }}</a>
  </div>
</template>

<script>
export default {
  props: ['slide', 'buttons']
}
</script>

<style>

</style>