<template>
  <div>
    <div v-if="!successMessage">
      <h1>Теперь немного информации о вас</h1>
      <form @submit.prevent="sendProfileData">
        <div class="radio-list" :class="{'error-block': !selectedCity && showErrors}">
          <label for="city" class="h4">
            Реальный регион проживания: *
            <span class="tip">(не нужно указывать "Москва", если вы не из Москвы)</span>
          </label>
          <input id="city" name="city" type="text" v-model="selectedCity" placeholder="Город">
        </div>
        <div class="radio-list" :class="{'error-block': !isWorkingNow && showErrors}">
          <div class="radio-list__name h4">Работаете ли вы в настоящее время? *</div>

          <radio v-model="isWorkingNow" name="is_working_now" value="1">Работаю</radio>
          <radio v-model="isWorkingNow" name="is_working_now" value="0">Не работаю</radio>
        </div>
        <div class="radio-list" :class="{'error-block': !typeOfWork && showErrors}">
          <div class="radio-list__name h4">В каком виде вы рассматриваете работу у нас? *</div>

          <radio v-model="typeOfWork" name="type_of_work" value="0">Постоянная основная работа</radio>
          <radio v-model="typeOfWork" name="type_of_work" value="1">Совместительство с другой работой</radio>
          <radio v-model="typeOfWork" name="type_of_work" value="2">Совместительство с учебой (очная форма)</radio>
          <radio v-model="typeOfWork" name="type_of_work" value="3">Совместительство с учебой (заочная форма)</radio>
        </div>
        <div class="radio-list" :class="{'error-block': !hasLilKids && showErrors}">
          <div class="radio-list__name h4">Есть ли у вас маленькие дети? *</div>

          <radio v-model="hasLilKids" name="has_lil_kids" value="1">Есть дети до 1,5 лет</radio>
          <radio v-model="hasLilKids" name="has_lil_kids" value="2">Есть дети до 3 лет</radio>
          <radio v-model="hasLilKids" name="has_lil_kids" value="0">Нет маленьких детей</radio>
        </div>
        <div class="radio-list">
          <div class="radio-list__name salary-header h4">Сколько вы зарабатывали в месяц на последнем месте работы (в
            рублях)?
          </div>
          <vue-slider
              :dotSize="[50, 24]"
              :dotStyle="{'border-radius': '50px'}"
              :interval="100"
              :marks="marks"
              :contained="true"
              :min="5000"
              :max="50000"
              tooltip="always"
              :tooltip-formatter="formatter"
              v-model="lastSalary"/>
        </div>
        <div class="radio-list" :class="{'error-block': !hasWorkExperienceInCallCenter && showErrors}">
          <div class="radio-list__name h4">Был ли опыт работы в колл-центре? *</div>
          <radio v-model="hasWorkExperienceInCallCenter" name="has_work_experience_in_call_center" value="1">Да</radio>
          <radio v-model="hasWorkExperienceInCallCenter" name="has_work_experience_in_call_center" value="0">Нет</radio>
        </div>
        <div class="radio-list" :class="{'error-block': !hasWorkExperienceInSales && showErrors}">
          <div class="radio-list__name h4">Был ли опыт работы в продажах? *</div>
          <radio v-model="hasWorkExperienceInSales" name="has_work_experience_in_sales" value="1">Да</radio>
          <radio v-model="hasWorkExperienceInSales" name="has_work_experience_in_sales" value="0">Нет</radio>
        </div>
        <div class="radio-list" :class="{'error-block': !hasWorkExperienceInRemoteJob && showErrors}">
          <div class="radio-list__name h4">Был ли опыт удаленной работы? *</div>
          <radio v-model="hasWorkExperienceInRemoteJob" name="has_work_experience_in_remote_job" value="1">Да</radio>
          <radio v-model="hasWorkExperienceInRemoteJob" name="has_work_experience_in_remote_job" value="0">Нет</radio>
        </div>
        <div class="textarea-block" :class="{'error-block': !soft && showErrors}">
          <label for="soft" class="textarea-block__name h4">
            Укажите через запятую, в каких компьютерных программах приходилось работать: *
          </label>
          <textarea v-model="soft" id="soft" name="soft" class="textarea-block__textarea"></textarea>
          <span class="tip">* - обязательно для заполнения</span>
        </div>

        <div class="errors" v-if="errors.length">
          <b>Пожалуйста исправьте указанные ошибки:</b>
          <ul>
            <li v-for="error in errors">{{ error }}</li>
          </ul>
        </div>

        <button :disabled="submitDisabled" class="button submit-button" type="submit">Отправить</button>
      </form>
    </div>
    <h1 v-else v-html="successMessage"></h1>
  </div>
</template>

<script>
import {Checkbox, Radio} from 'vue-checkbox-radio';
import VueSlider from 'vue-slider-component'
import '@/assets/css/vue-slider-component/antd.css'
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@/assets/css/autocomplete/ac.css'

export default {
  name: "about-employee",
  data() {
    return {
      marks: {
        '5000': '5 000 р.',
        '50000': '50 000 р.'
      },
      formatter: v => `${('' + v).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} р.`,
      successMessage: '',
      lastSalary: 26000,
      selectedCity: null,
      isWorkingNow: null,
      typeOfWork: null,
      hasLilKids: null,
      hasWorkExperienceInCallCenter: null,
      hasWorkExperienceInSales: null,
      hasWorkExperienceInRemoteJob: null,
      soft: null,
      errors: [],
      userId: null,
      submitDisabled: false,
      showErrors: false,
    }
  },
  created() {
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    this.userId = params.get("id");
  },
  components: {
    Radio,
    Checkbox,
    VueSlider,
    Autocomplete,
  },
  methods: {
    search(input) {
      let geonamesApi = 'https://secure.geonames.org/searchJSON?';
      let params = "username=provider&" +
          "lang=ru&" +
          "country=RU&" +
          "country=UA&" +
          "country=BY&" +
          "country=AZ&" +
          "country=AM&" +
          "country=KZ&" +
          "country=KG&" +
          "country=MD&" +
          "country=TJ&" +
          "country=UZ&" +
          "country=GE&" +
          "country=TM&" +
          "featureClass=P&" +
          "orderby=relevance&" +
          "maxRows=10&" +
          "isNameRequired=true&";

      const url = `${geonamesApi}${params}name_startsWith=${encodeURI(input.trim())}`;

      return new Promise(resolve => {
        if (input.length < 3) {
          return resolve([])
        }

        fetch(url)
            .then(response => response.json())
            .then(data => {
              resolve(data.geonames.filter(function (el) {
                return el.population !== 0;
              }))
            })
      })
    },
    getResultValue(result) {
      return (result.name === result.adminName1) ? result.name : result.name + ', ' + result.adminName1;
    },
    handleSubmit(result) {
      this.selectedCity = (result.name === result.adminName1) ? result.name : result.name + ', ' + result.adminName1;
    },
    sendProfileData() {
      if (!this.checkForm()) {
        return;
      }

      this.submitDisabled = true

      let url = '/admin/callCenterWorker/client/saveDataFromQuestionary/';

      fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({profileData: this.$data})
      })
          .then(response => response.json())
          .then(json => {
            if (json.success) {
              this.successMessage = json.success;
            } else {
              this.submitDisabled = false;
              this.errors.push(json.error);
            }
          })

      /*console.log(
          this.isWorkingNow,
          this.typeOfWork,
          this.hasLilKids,
          this.lastSalary,
          this.hasWorkExperienceInCallCenter,
          this.hasWorkExperienceInSales,
          this.hasWorkExperienceInRemoteJob,
          this.selectedCity,
          this.soft,
      );*/
    },
    checkForm: function (e) {
      this.errors = [];

      if (!this.isWorkingNow) {
        this.errors.push('Заполните поле Работаете ли вы в настоящее время?');
      }
      if (!this.typeOfWork) {
        this.errors.push('Заполните поле В каком виде вы рассматриваете работу у нас?');
      }
      if (!this.hasLilKids) {
        this.errors.push('Заполните поле Есть ли у вас маленькие дети?');
      }
      if (!this.lastSalary) {
        this.errors.push('Заполните поле Сколько вы зарабатывали в месяц на последнем месте работы');
      }
      if (!this.hasWorkExperienceInCallCenter) {
        this.errors.push('Заполните поле Был ли опыт работы в колл-центре?');
      }
      if (!this.hasWorkExperienceInSales) {
        this.errors.push('Заполните поле Был ли опыт работы в продажах?');
      }
      if (!this.hasWorkExperienceInRemoteJob) {
        this.errors.push('Заполните поле Был ли опыт удаленной работы?');
      }
      if (!this.selectedCity) {
        this.errors.push('Заполните поле Реальный регион проживания городом из списка! Произвольный город не принимается');
      }
      if (!this.soft) {
        this.errors.push('Заполните поле Укажите через запятую, в каких компьютерных программах приходилось работать');
      }

      if (!this.errors.length) {
        return true;
      } else {
        this.showErrors = true;
        return false
      }
    },
  }
}
</script>

<style scoped>
* {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.errors {
  margin: 0 21px 0;
}

.radio-list {
  margin-bottom: 30px;
}

.radio-list__name,
.textarea-block__name {
  margin-bottom: 10px;
  display: block;
}

.salary-header {
  margin-bottom: 50px;
}

.vue-slider {
  margin-bottom: 50px;
}

.textarea-block__textarea {
  width: 100%;
  height: 120px;
  resize: vertical;
  box-sizing: border-box;
  margin-bottom: 30px;
}

.h4 {
  text-align: left;
}

.tip {
  display: block;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, .5);
  font-size: .9rem;
}

.errors ul {
  margin: 10px 0 21px;
  padding-left: 21px;
}

.error-block {
  border: 1px solid #ff6464;
  border-radius: 10px;
  padding: 13px 19px;
  margin: 0 0 30px;
}
</style>

<style>
.radio-component label {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.radio-component > input + label > .input-box {
  margin-right: 10px !important;
  min-width: 20px;
  width: 20px !important;
  height: 20px !important;
}
</style>