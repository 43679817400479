<template>
  <div id="app" class="page">
    <slider v-if="!error"/>
    <h1 v-else>
      Вам дали неверную ссылку<br>(отсутствует ID)
    </h1>
  </div>
</template>

<script>
import Slider from '@/components/Slider'

export default {
  name: 'App',
  components: {
    Slider
  },
  data() {
    return {
      error: false
    }
  },
  created() {
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    let userId = params.get("id");
    if (userId === null) {
      this.error = true;
    }
  },
}
</script>

<style>

</style>
